//! moment.js locale configuration
//! locale : Talossan [tzl]
//! author : Robin van der Vliet : https://github.com/robin0van0der0v
//! author : Iustì Canun

import moment from '../moment';

// After the year there should be a slash and the amount of years since December 26, 1979 in Roman numerals.
// This is currently too difficult (maybe even impossible) to add.
export default moment.defineLocale('tzl', {
    months: 'Januar_Fevraglh_Març_Avrïu_Mai_Gün_Julia_Guscht_Setemvar_Listopäts_Noemvar_Zecemvar'.split(
        '_'
    ),
    monthsShort: 'Jan_Fev_Mar_Avr_Mai_Gün_Jul_Gus_Set_Lis_Noe_Zec'.split('_'),
    weekdays: 'Súladi_Lúneçi_Maitzi_Márcuri_Xhúadi_Viénerçi_Sáturi'.split('_'),
    weekdaysShort: 'Súl_Lún_Mai_Már_Xhú_Vié_Sát'.split('_'),
    weekdaysMin: 'Sú_Lú_Ma_Má_Xh_Vi_Sá'.split('_'),
    longDateFormat: {
        LT: 'HH.mm',
        LTS: 'HH.mm.ss',
        L: 'DD.MM.YYYY',
        LL: 'D. MMMM [dallas] YYYY',
        LLL: 'D. MMMM [dallas] YYYY HH.mm',
        LLLL: 'dddd, [li] D. MMMM [dallas] YYYY HH.mm',
    },
    meridiemParse: /d\'o|d\'a/i,
    isPM: function (input) {
        return "d'o" === input.toLowerCase();
    },
    meridiem: function (hours, minutes, isLower) {
        if (hours > 11) {
            return isLower ? "d'o" : "D'O";
        } else {
            return isLower ? "d'a" : "D'A";
        }
    },
    calendar: {
        sameDay: '[oxhi à] LT',
        nextDay: '[demà à] LT',
        nextWeek: 'dddd [à] LT',
        lastDay: '[ieiri à] LT',
        lastWeek: '[sür el] dddd [lasteu à] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'osprei %s',
        past: 'ja%s',
        s: processRelativeTime,
        ss: processRelativeTime,
        m: processRelativeTime,
        mm: processRelativeTime,
        h: processRelativeTime,
        hh: processRelativeTime,
        d: processRelativeTime,
        dd: processRelativeTime,
        M: processRelativeTime,
        MM: processRelativeTime,
        y: processRelativeTime,
        yy: processRelativeTime,
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: '%d.',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});

function processRelativeTime(number, withoutSuffix, key, isFuture) {
    var format = {
        s: ['viensas secunds', "'iensas secunds"],
        ss: [number + ' secunds', '' + number + ' secunds'],
        m: ["'n míut", "'iens míut"],
        mm: [number + ' míuts', '' + number + ' míuts'],
        h: ["'n þora", "'iensa þora"],
        hh: [number + ' þoras', '' + number + ' þoras'],
        d: ["'n ziua", "'iensa ziua"],
        dd: [number + ' ziuas', '' + number + ' ziuas'],
        M: ["'n mes", "'iens mes"],
        MM: [number + ' mesen', '' + number + ' mesen'],
        y: ["'n ar", "'iens ar"],
        yy: [number + ' ars', '' + number + ' ars'],
    };
    return isFuture
        ? format[key][0]
        : withoutSuffix
        ? format[key][0]
        : format[key][1];
}
