//! moment.js locale configuration
//! locale : Northern Sami [se]
//! authors : Bård Rolstad Henriksen : https://github.com/karamell

import moment from '../moment';

export default moment.defineLocale('se', {
    months: 'ođđajagemánnu_guovvamánnu_njukčamánnu_cuoŋománnu_miessemánnu_geassemánnu_suoidnemánnu_borgemánnu_čakčamánnu_golggotmánnu_skábmamánnu_juovlamánnu'.split(
        '_'
    ),
    monthsShort:
        'ođđj_guov_njuk_cuo_mies_geas_suoi_borg_čakč_golg_skáb_juov'.split('_'),
    weekdays:
        'sotnabeaivi_vuossárga_maŋŋebárga_gaskavahkku_duorastat_bearjadat_lávvardat'.split(
            '_'
        ),
    weekdaysShort: 'sotn_vuos_maŋ_gask_duor_bear_láv'.split('_'),
    weekdaysMin: 's_v_m_g_d_b_L'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'MMMM D. [b.] YYYY',
        LLL: 'MMMM D. [b.] YYYY [ti.] HH:mm',
        LLLL: 'dddd, MMMM D. [b.] YYYY [ti.] HH:mm',
    },
    calendar: {
        sameDay: '[otne ti] LT',
        nextDay: '[ihttin ti] LT',
        nextWeek: 'dddd [ti] LT',
        lastDay: '[ikte ti] LT',
        lastWeek: '[ovddit] dddd [ti] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s geažes',
        past: 'maŋit %s',
        s: 'moadde sekunddat',
        ss: '%d sekunddat',
        m: 'okta minuhta',
        mm: '%d minuhtat',
        h: 'okta diimmu',
        hh: '%d diimmut',
        d: 'okta beaivi',
        dd: '%d beaivvit',
        M: 'okta mánnu',
        MM: '%d mánut',
        y: 'okta jahki',
        yy: '%d jagit',
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: '%d.',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
