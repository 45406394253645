//! moment.js locale configuration
//! locale : Faroese [fo]
//! author : Ragnar Johannesen : https://github.com/ragnar123
//! author : Kristian Sakarisson : https://github.com/sakarisson

import moment from '../moment';

export default moment.defineLocale('fo', {
    months: 'januar_februar_mars_apríl_mai_juni_juli_august_september_oktober_november_desember'.split(
        '_'
    ),
    monthsShort: 'jan_feb_mar_apr_mai_jun_jul_aug_sep_okt_nov_des'.split('_'),
    weekdays:
        'sunnudagur_mánadagur_týsdagur_mikudagur_hósdagur_fríggjadagur_leygardagur'.split(
            '_'
        ),
    weekdaysShort: 'sun_mán_týs_mik_hós_frí_ley'.split('_'),
    weekdaysMin: 'su_má_tý_mi_hó_fr_le'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D. MMMM, YYYY HH:mm',
    },
    calendar: {
        sameDay: '[Í dag kl.] LT',
        nextDay: '[Í morgin kl.] LT',
        nextWeek: 'dddd [kl.] LT',
        lastDay: '[Í gjár kl.] LT',
        lastWeek: '[síðstu] dddd [kl] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'um %s',
        past: '%s síðani',
        s: 'fá sekund',
        ss: '%d sekundir',
        m: 'ein minuttur',
        mm: '%d minuttir',
        h: 'ein tími',
        hh: '%d tímar',
        d: 'ein dagur',
        dd: '%d dagar',
        M: 'ein mánaður',
        MM: '%d mánaðir',
        y: 'eitt ár',
        yy: '%d ár',
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: '%d.',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
