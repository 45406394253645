//! moment.js locale configuration
//! locale : Esperanto [eo]
//! author : Colin Dean : https://github.com/colindean
//! author : Mia Nordentoft Imperatori : https://github.com/miestasmia
//! comment : miestasmia corrected the translation by colindean
//! comment : Vivakvo corrected the translation by colindean and miestasmia

import moment from '../moment';

export default moment.defineLocale('eo', {
    months: 'januaro_februaro_marto_aprilo_majo_junio_julio_aŭgusto_septembro_oktobro_novembro_decembro'.split(
        '_'
    ),
    monthsShort: 'jan_feb_mart_apr_maj_jun_jul_aŭg_sept_okt_nov_dec'.split('_'),
    weekdays: 'dimanĉo_lundo_mardo_merkredo_ĵaŭdo_vendredo_sabato'.split('_'),
    weekdaysShort: 'dim_lun_mard_merk_ĵaŭ_ven_sab'.split('_'),
    weekdaysMin: 'di_lu_ma_me_ĵa_ve_sa'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: '[la] D[-an de] MMMM, YYYY',
        LLL: '[la] D[-an de] MMMM, YYYY HH:mm',
        LLLL: 'dddd[n], [la] D[-an de] MMMM, YYYY HH:mm',
        llll: 'ddd, [la] D[-an de] MMM, YYYY HH:mm',
    },
    meridiemParse: /[ap]\.t\.m/i,
    isPM: function (input) {
        return input.charAt(0).toLowerCase() === 'p';
    },
    meridiem: function (hours, minutes, isLower) {
        if (hours > 11) {
            return isLower ? 'p.t.m.' : 'P.T.M.';
        } else {
            return isLower ? 'a.t.m.' : 'A.T.M.';
        }
    },
    calendar: {
        sameDay: '[Hodiaŭ je] LT',
        nextDay: '[Morgaŭ je] LT',
        nextWeek: 'dddd[n je] LT',
        lastDay: '[Hieraŭ je] LT',
        lastWeek: '[pasintan] dddd[n je] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'post %s',
        past: 'antaŭ %s',
        s: 'kelkaj sekundoj',
        ss: '%d sekundoj',
        m: 'unu minuto',
        mm: '%d minutoj',
        h: 'unu horo',
        hh: '%d horoj',
        d: 'unu tago', //ne 'diurno', ĉar estas uzita por proksimumo
        dd: '%d tagoj',
        M: 'unu monato',
        MM: '%d monatoj',
        y: 'unu jaro',
        yy: '%d jaroj',
    },
    dayOfMonthOrdinalParse: /\d{1,2}a/,
    ordinal: '%da',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 7, // The week that contains Jan 7th is the first week of the year.
    },
});
