//! moment.js locale configuration
//! locale : Urdu [ur]
//! author : Sawood Alam : https://github.com/ibnesayeed
//! author : Zack : https://github.com/ZackVision

import moment from '../moment';

var months = [
        'جنوری',
        'فروری',
        'مارچ',
        'اپریل',
        'مئی',
        'جون',
        'جولائی',
        'اگست',
        'ستمبر',
        'اکتوبر',
        'نومبر',
        'دسمبر',
    ],
    days = ['اتوار', 'پیر', 'منگل', 'بدھ', 'جمعرات', 'جمعہ', 'ہفتہ'];

export default moment.defineLocale('ur', {
    months: months,
    monthsShort: months,
    weekdays: days,
    weekdaysShort: days,
    weekdaysMin: days,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd، D MMMM YYYY HH:mm',
    },
    meridiemParse: /صبح|شام/,
    isPM: function (input) {
        return 'شام' === input;
    },
    meridiem: function (hour, minute, isLower) {
        if (hour < 12) {
            return 'صبح';
        }
        return 'شام';
    },
    calendar: {
        sameDay: '[آج بوقت] LT',
        nextDay: '[کل بوقت] LT',
        nextWeek: 'dddd [بوقت] LT',
        lastDay: '[گذشتہ روز بوقت] LT',
        lastWeek: '[گذشتہ] dddd [بوقت] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s بعد',
        past: '%s قبل',
        s: 'چند سیکنڈ',
        ss: '%d سیکنڈ',
        m: 'ایک منٹ',
        mm: '%d منٹ',
        h: 'ایک گھنٹہ',
        hh: '%d گھنٹے',
        d: 'ایک دن',
        dd: '%d دن',
        M: 'ایک ماہ',
        MM: '%d ماہ',
        y: 'ایک سال',
        yy: '%d سال',
    },
    preparse: function (string) {
        return string.replace(/،/g, ',');
    },
    postformat: function (string) {
        return string.replace(/,/g, '،');
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
