//! moment.js locale configuration
//! locale : Uyghur (China) [ug-cn]
//! author: boyaq : https://github.com/boyaq

import moment from '../moment';

export default moment.defineLocale('ug-cn', {
    months: 'يانۋار_فېۋرال_مارت_ئاپرېل_ماي_ئىيۇن_ئىيۇل_ئاۋغۇست_سېنتەبىر_ئۆكتەبىر_نويابىر_دېكابىر'.split(
        '_'
    ),
    monthsShort:
        'يانۋار_فېۋرال_مارت_ئاپرېل_ماي_ئىيۇن_ئىيۇل_ئاۋغۇست_سېنتەبىر_ئۆكتەبىر_نويابىر_دېكابىر'.split(
            '_'
        ),
    weekdays: 'يەكشەنبە_دۈشەنبە_سەيشەنبە_چارشەنبە_پەيشەنبە_جۈمە_شەنبە'.split(
        '_'
    ),
    weekdaysShort: 'يە_دۈ_سە_چا_پە_جۈ_شە'.split('_'),
    weekdaysMin: 'يە_دۈ_سە_چا_پە_جۈ_شە'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY-يىلىM-ئاينىڭD-كۈنى',
        LLL: 'YYYY-يىلىM-ئاينىڭD-كۈنى، HH:mm',
        LLLL: 'dddd، YYYY-يىلىM-ئاينىڭD-كۈنى، HH:mm',
    },
    meridiemParse: /يېرىم كېچە|سەھەر|چۈشتىن بۇرۇن|چۈش|چۈشتىن كېيىن|كەچ/,
    meridiemHour: function (hour, meridiem) {
        if (hour === 12) {
            hour = 0;
        }
        if (
            meridiem === 'يېرىم كېچە' ||
            meridiem === 'سەھەر' ||
            meridiem === 'چۈشتىن بۇرۇن'
        ) {
            return hour;
        } else if (meridiem === 'چۈشتىن كېيىن' || meridiem === 'كەچ') {
            return hour + 12;
        } else {
            return hour >= 11 ? hour : hour + 12;
        }
    },
    meridiem: function (hour, minute, isLower) {
        var hm = hour * 100 + minute;
        if (hm < 600) {
            return 'يېرىم كېچە';
        } else if (hm < 900) {
            return 'سەھەر';
        } else if (hm < 1130) {
            return 'چۈشتىن بۇرۇن';
        } else if (hm < 1230) {
            return 'چۈش';
        } else if (hm < 1800) {
            return 'چۈشتىن كېيىن';
        } else {
            return 'كەچ';
        }
    },
    calendar: {
        sameDay: '[بۈگۈن سائەت] LT',
        nextDay: '[ئەتە سائەت] LT',
        nextWeek: '[كېلەركى] dddd [سائەت] LT',
        lastDay: '[تۆنۈگۈن] LT',
        lastWeek: '[ئالدىنقى] dddd [سائەت] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s كېيىن',
        past: '%s بۇرۇن',
        s: 'نەچچە سېكونت',
        ss: '%d سېكونت',
        m: 'بىر مىنۇت',
        mm: '%d مىنۇت',
        h: 'بىر سائەت',
        hh: '%d سائەت',
        d: 'بىر كۈن',
        dd: '%d كۈن',
        M: 'بىر ئاي',
        MM: '%d ئاي',
        y: 'بىر يىل',
        yy: '%d يىل',
    },

    dayOfMonthOrdinalParse: /\d{1,2}(-كۈنى|-ئاي|-ھەپتە)/,
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return number + '-كۈنى';
            case 'w':
            case 'W':
                return number + '-ھەپتە';
            default:
                return number;
        }
    },
    preparse: function (string) {
        return string.replace(/،/g, ',');
    },
    postformat: function (string) {
        return string.replace(/,/g, '،');
    },
    week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 7, // The week that contains Jan 1st is the first week of the year.
    },
});
