//! moment.js locale configuration
//! locale : Lao [lo]
//! author : Ryan Hart : https://github.com/ryanhart2

import moment from '../moment';

export default moment.defineLocale('lo', {
    months: 'ມັງກອນ_ກຸມພາ_ມີນາ_ເມສາ_ພຶດສະພາ_ມິຖຸນາ_ກໍລະກົດ_ສິງຫາ_ກັນຍາ_ຕຸລາ_ພະຈິກ_ທັນວາ'.split(
        '_'
    ),
    monthsShort:
        'ມັງກອນ_ກຸມພາ_ມີນາ_ເມສາ_ພຶດສະພາ_ມິຖຸນາ_ກໍລະກົດ_ສິງຫາ_ກັນຍາ_ຕຸລາ_ພະຈິກ_ທັນວາ'.split(
            '_'
        ),
    weekdays: 'ອາທິດ_ຈັນ_ອັງຄານ_ພຸດ_ພະຫັດ_ສຸກ_ເສົາ'.split('_'),
    weekdaysShort: 'ທິດ_ຈັນ_ອັງຄານ_ພຸດ_ພະຫັດ_ສຸກ_ເສົາ'.split('_'),
    weekdaysMin: 'ທ_ຈ_ອຄ_ພ_ພຫ_ສກ_ສ'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'ວັນdddd D MMMM YYYY HH:mm',
    },
    meridiemParse: /ຕອນເຊົ້າ|ຕອນແລງ/,
    isPM: function (input) {
        return input === 'ຕອນແລງ';
    },
    meridiem: function (hour, minute, isLower) {
        if (hour < 12) {
            return 'ຕອນເຊົ້າ';
        } else {
            return 'ຕອນແລງ';
        }
    },
    calendar: {
        sameDay: '[ມື້ນີ້ເວລາ] LT',
        nextDay: '[ມື້ອື່ນເວລາ] LT',
        nextWeek: '[ວັນ]dddd[ໜ້າເວລາ] LT',
        lastDay: '[ມື້ວານນີ້ເວລາ] LT',
        lastWeek: '[ວັນ]dddd[ແລ້ວນີ້ເວລາ] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'ອີກ %s',
        past: '%sຜ່ານມາ',
        s: 'ບໍ່ເທົ່າໃດວິນາທີ',
        ss: '%d ວິນາທີ',
        m: '1 ນາທີ',
        mm: '%d ນາທີ',
        h: '1 ຊົ່ວໂມງ',
        hh: '%d ຊົ່ວໂມງ',
        d: '1 ມື້',
        dd: '%d ມື້',
        M: '1 ເດືອນ',
        MM: '%d ເດືອນ',
        y: '1 ປີ',
        yy: '%d ປີ',
    },
    dayOfMonthOrdinalParse: /(ທີ່)\d{1,2}/,
    ordinal: function (number) {
        return 'ທີ່' + number;
    },
});
