//! moment.js locale configuration
//! locale : Maldivian [dv]
//! author : Jawish Hameed : https://github.com/jawish

import moment from '../moment';

var months = [
        'ޖެނުއަރީ',
        'ފެބްރުއަރީ',
        'މާރިޗު',
        'އޭޕްރީލު',
        'މޭ',
        'ޖޫން',
        'ޖުލައި',
        'އޯގަސްޓު',
        'ސެޕްޓެމްބަރު',
        'އޮކްޓޯބަރު',
        'ނޮވެމްބަރު',
        'ޑިސެމްބަރު',
    ],
    weekdays = [
        'އާދިއްތަ',
        'ހޯމަ',
        'އަންގާރަ',
        'ބުދަ',
        'ބުރާސްފަތި',
        'ހުކުރު',
        'ހޮނިހިރު',
    ];

export default moment.defineLocale('dv', {
    months: months,
    monthsShort: months,
    weekdays: weekdays,
    weekdaysShort: weekdays,
    weekdaysMin: 'އާދި_ހޯމަ_އަން_ބުދަ_ބުރާ_ހުކު_ހޮނި'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'D/M/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
    },
    meridiemParse: /މކ|މފ/,
    isPM: function (input) {
        return 'މފ' === input;
    },
    meridiem: function (hour, minute, isLower) {
        if (hour < 12) {
            return 'މކ';
        } else {
            return 'މފ';
        }
    },
    calendar: {
        sameDay: '[މިއަދު] LT',
        nextDay: '[މާދަމާ] LT',
        nextWeek: 'dddd LT',
        lastDay: '[އިއްޔެ] LT',
        lastWeek: '[ފާއިތުވި] dddd LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'ތެރޭގައި %s',
        past: 'ކުރިން %s',
        s: 'ސިކުންތުކޮޅެއް',
        ss: 'd% ސިކުންތު',
        m: 'މިނިޓެއް',
        mm: 'މިނިޓު %d',
        h: 'ގަޑިއިރެއް',
        hh: 'ގަޑިއިރު %d',
        d: 'ދުވަހެއް',
        dd: 'ދުވަސް %d',
        M: 'މަހެއް',
        MM: 'މަސް %d',
        y: 'އަހަރެއް',
        yy: 'އަހަރު %d',
    },
    preparse: function (string) {
        return string.replace(/،/g, ',');
    },
    postformat: function (string) {
        return string.replace(/,/g, '،');
    },
    week: {
        dow: 7, // Sunday is the first day of the week.
        doy: 12, // The week that contains Jan 12th is the first week of the year.
    },
});
