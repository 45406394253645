//! moment.js locale configuration
//! locale : Turkmen [tk]
//! author : Atamyrat Abdyrahmanov : https://github.com/atamyratabdy

import moment from '../moment';

var suffixes = {
    1: "'inji",
    5: "'inji",
    8: "'inji",
    70: "'inji",
    80: "'inji",
    2: "'nji",
    7: "'nji",
    20: "'nji",
    50: "'nji",
    3: "'ünji",
    4: "'ünji",
    100: "'ünji",
    6: "'njy",
    9: "'unjy",
    10: "'unjy",
    30: "'unjy",
    60: "'ynjy",
    90: "'ynjy",
};

export default moment.defineLocale('tk', {
    months: 'Ýanwar_Fewral_Mart_Aprel_Maý_Iýun_Iýul_Awgust_Sentýabr_Oktýabr_Noýabr_Dekabr'.split(
        '_'
    ),
    monthsShort: 'Ýan_Few_Mar_Apr_Maý_Iýn_Iýl_Awg_Sen_Okt_Noý_Dek'.split('_'),
    weekdays: 'Ýekşenbe_Duşenbe_Sişenbe_Çarşenbe_Penşenbe_Anna_Şenbe'.split(
        '_'
    ),
    weekdaysShort: 'Ýek_Duş_Siş_Çar_Pen_Ann_Şen'.split('_'),
    weekdaysMin: 'Ýk_Dş_Sş_Çr_Pn_An_Şn'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD.MM.YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[bugün sagat] LT',
        nextDay: '[ertir sagat] LT',
        nextWeek: '[indiki] dddd [sagat] LT',
        lastDay: '[düýn] LT',
        lastWeek: '[geçen] dddd [sagat] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s soň',
        past: '%s öň',
        s: 'birnäçe sekunt',
        m: 'bir minut',
        mm: '%d minut',
        h: 'bir sagat',
        hh: '%d sagat',
        d: 'bir gün',
        dd: '%d gün',
        M: 'bir aý',
        MM: '%d aý',
        y: 'bir ýyl',
        yy: '%d ýyl',
    },
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'Do':
            case 'DD':
                return number;
            default:
                if (number === 0) {
                    // special case for zero
                    return number + "'unjy";
                }
                var a = number % 10,
                    b = (number % 100) - a,
                    c = number >= 100 ? 100 : null;
                return number + (suffixes[a] || suffixes[b] || suffixes[c]);
        }
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 7, // The week that contains Jan 7th is the first week of the year.
    },
});
