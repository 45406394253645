//! moment.js locale configuration
//! locale : Irish or Irish Gaelic [ga]
//! author : André Silva : https://github.com/askpt

import moment from '../moment';

var months = [
        'Eanáir',
        'Feabhra',
        'Márta',
        'Aibreán',
        'Bealtaine',
        'Meitheamh',
        'Iúil',
        'Lúnasa',
        'Meán Fómhair',
        'Deireadh Fómhair',
        'Samhain',
        'Nollaig',
    ],
    monthsShort = [
        'Ean',
        'Feabh',
        'Márt',
        'Aib',
        'Beal',
        'Meith',
        'Iúil',
        'Lún',
        'M.F.',
        'D.F.',
        'Samh',
        'Noll',
    ],
    weekdays = [
        'Dé Domhnaigh',
        'Dé Luain',
        'Dé Máirt',
        'Dé Céadaoin',
        'Déardaoin',
        'Dé hAoine',
        'Dé Sathairn',
    ],
    weekdaysShort = ['Domh', 'Luan', 'Máirt', 'Céad', 'Déar', 'Aoine', 'Sath'],
    weekdaysMin = ['Do', 'Lu', 'Má', 'Cé', 'Dé', 'A', 'Sa'];

export default moment.defineLocale('ga', {
    months: months,
    monthsShort: monthsShort,
    monthsParseExact: true,
    weekdays: weekdays,
    weekdaysShort: weekdaysShort,
    weekdaysMin: weekdaysMin,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd, D MMMM YYYY HH:mm',
    },
    calendar: {
        sameDay: '[Inniu ag] LT',
        nextDay: '[Amárach ag] LT',
        nextWeek: 'dddd [ag] LT',
        lastDay: '[Inné ag] LT',
        lastWeek: 'dddd [seo caite] [ag] LT',
        sameElse: 'L',
    },
    relativeTime: {
        future: 'i %s',
        past: '%s ó shin',
        s: 'cúpla soicind',
        ss: '%d soicind',
        m: 'nóiméad',
        mm: '%d nóiméad',
        h: 'uair an chloig',
        hh: '%d uair an chloig',
        d: 'lá',
        dd: '%d lá',
        M: 'mí',
        MM: '%d míonna',
        y: 'bliain',
        yy: '%d bliain',
    },
    dayOfMonthOrdinalParse: /\d{1,2}(d|na|mh)/,
    ordinal: function (number) {
        var output = number === 1 ? 'd' : number % 10 === 2 ? 'na' : 'mh';
        return number + output;
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
});
