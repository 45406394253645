//! moment.js locale configuration
//! locale : Basque [eu]
//! author : Eneko Illarramendi : https://github.com/eillarra

import moment from '../moment';

export default moment.defineLocale('eu', {
    months: 'urtarrila_otsaila_martxoa_apirila_maiatza_ekaina_uztaila_abuztua_iraila_urria_azaroa_abendua'.split(
        '_'
    ),
    monthsShort:
        'urt._ots._mar._api._mai._eka._uzt._abu._ira._urr._aza._abe.'.split(
            '_'
        ),
    monthsParseExact: true,
    weekdays:
        'igandea_astelehena_asteartea_asteazkena_osteguna_ostirala_larunbata'.split(
            '_'
        ),
    weekdaysShort: 'ig._al._ar._az._og._ol._lr.'.split('_'),
    weekdaysMin: 'ig_al_ar_az_og_ol_lr'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY-MM-DD',
        LL: 'YYYY[ko] MMMM[ren] D[a]',
        LLL: 'YYYY[ko] MMMM[ren] D[a] HH:mm',
        LLLL: 'dddd, YYYY[ko] MMMM[ren] D[a] HH:mm',
        l: 'YYYY-M-D',
        ll: 'YYYY[ko] MMM D[a]',
        lll: 'YYYY[ko] MMM D[a] HH:mm',
        llll: 'ddd, YYYY[ko] MMM D[a] HH:mm',
    },
    calendar: {
        sameDay: '[gaur] LT[etan]',
        nextDay: '[bihar] LT[etan]',
        nextWeek: 'dddd LT[etan]',
        lastDay: '[atzo] LT[etan]',
        lastWeek: '[aurreko] dddd LT[etan]',
        sameElse: 'L',
    },
    relativeTime: {
        future: '%s barru',
        past: 'duela %s',
        s: 'segundo batzuk',
        ss: '%d segundo',
        m: 'minutu bat',
        mm: '%d minutu',
        h: 'ordu bat',
        hh: '%d ordu',
        d: 'egun bat',
        dd: '%d egun',
        M: 'hilabete bat',
        MM: '%d hilabete',
        y: 'urte bat',
        yy: '%d urte',
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: '%d.',
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 7, // The week that contains Jan 7th is the first week of the year.
    },
});
